body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  min-height: 100vh;
  background: #f8f9fa;
  padding: 2rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
}

h1 {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 2rem;
  color: #1a1a1a;
  text-align: center;
  letter-spacing: -0.02em;
}

.logo {
  font-weight: 600;
  color: #0066ff;
  margin-right: 0.5rem;
}

.form-container {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.input-field {
  width: 100%;
  padding: 1rem;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  transition: border-color 0.2s ease;
  box-sizing: border-box;
}

textarea.input-field {
  min-height: 100px;
  resize: vertical;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}

.input-field:focus {
  outline: none;
  border-color: #0066ff;
}

.validate-button {
  width: 100%;
  padding: 1rem;
  background: #0066ff;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin-top: 0.5rem;
}

.validate-button:hover {
  background: #0052cc;
}

.validate-button:disabled {
  background: #ccc;
  cursor: not-allowed;
  opacity: 0.7;
}

.validation-result {
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 8px;
  font-weight: 500;
  position: relative;
}

.validation-result.success {
  background: #e6f4ea;
  color: #137333;
  border: 1px solid #a5d6a7;
}

.validation-result.error {
  background: #fce8e6;
  color: #c5221f;
  border: 1px solid #ef9a9a;
}

.explanation {
  font-size: 0.9rem;
  color: #333;
  margin-top: 0.5rem;
  padding: 0.75rem;
  border-left: 4px solid #0066ff;
  background: #f0f8ff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease;
}

.explanation:hover {
  background: #e6f7ff;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.input-field::placeholder {
  color: #999;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  font-size: 1rem;
  font-style: normal;
}

textarea.input-field::placeholder {
  line-height: 1.5;
}

@media (max-width: 600px) {
  .App {
    padding: 1rem;
  }
  
  .App-header {
    padding: 1rem;
  }
  
  h1 {
    font-size: 1.75rem;
  }
}

.footer {
  text-align: center;
  margin-top: 2rem;
  padding: 1rem;
  background: #f8f9fa; /* Light background */
  border-top: 1px solid #e0e0e0; /* Subtle top border */
}

.footer a {
  color: #0066ff; /* GitHub link color */
  text-decoration: none;
  font-weight: 500;
  display: inline-flex; /* Align icon and text */
  align-items: center; /* Center vertically */
  font-size: 1rem; /* Font size */
  transition: color 0.3s ease; /* Smooth color transition */
}

.footer a:hover {
  color: #004bb5; /* Darker blue on hover */
  text-decoration: underline; /* Underline on hover */
}

.footer svg {
  margin-right: 0.5rem; /* Space between icon and text */
  width: 24px; /* Icon size */
  height: 24px; /* Icon size */
  fill: currentColor; /* Make the icon color match the text */
}
